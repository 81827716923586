import { ProfileService } from '@/services/local-storage';
import api from '@/services/api';

export const state = () => ({
  organizationList: [],
  selectedOrganization: null,
  selectedOrganizationDetail: {},
  profile: ProfileService.get(),
});

// getters
export const getters = {
  userRole: (state) => {
    let profile = ProfileService.get()
    let userRole = ''
    if (profile.is_superadmin) {
      return 0
    }
    if (state.selectedOrganization) {
      const userOrganization = profile.organizations.map(item => item.pivot)
      const selectedOrganization = userOrganization.find(item => item.organization_id == state.selectedOrganization)
      userRole = selectedOrganization.organization_role ?? ''
    }
    switch(userRole) {
      case 'planner':
        return 1
      case 'maintenance':
        return 2
      default:
        return 3
    }
  },
};

// actions
export const actions = {
  async getOrganizationList({ commit }) {
    try {
      const res = await api.getOrganizations();
      const organizations = res.data.data;
      commit('SET_ORGANIZATION_LIST', organizations);
    } catch (error) {
      console.log(error);
    }
  },
};

// mutations
export const mutations = {
  SET_ORGANIZATION_LIST(state, organizationListData) {
    state.organizationList = organizationListData;
  },
  SET_SELECTED_ORGANIZATION(state, selectedOrganizationData) {
    state.selectedOrganization = selectedOrganizationData;
  },
  SET_ORGANIZATION_DETAIL(state, organizationDetailData) {
    state.selectedOrganizationDetail = organizationDetailData;
  },
};
