import Axios from 'axios';
import { TokenService, ProfileService } from './local-storage';

function destroyLocalToken() {
  TokenService.remove();
  ProfileService.remove();
  router.push('/login');
}

// create axios instance
const instanceAxios = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// update token every request
instanceAxios.interceptors.request.use(
    (config) => {
      const token = TokenService.get();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => { Promise.reject(error), destroyLocalToken() }
  );
  
const endpoints = {
    login(data) {
        return instanceAxios.post(`/api/auth/token`, data);
    },
    // Profile
    getProfile(){
        return instanceAxios.get(`/api/profile`);
    },
    updateProfile(data){
        return instanceAxios.put(`/api/profile`, data);
    },
    updateProfilePassword(data){
        return instanceAxios.put(`/api/profile/password`, data);
    },
    // Organization 
    getOrganizations(params) {
      return instanceAxios.get(`api/organizations`, { params });
    },
    getOrganizationDetail(organizationId, params) {
      return instanceAxios.get(`/api/organizations/${organizationId}`, { params });
    },
    createOrganization(data) {
      return instanceAxios.post(`/api/organizations`, data);
    },
    updateOrganization(organizationId, data) {
      return instanceAxios.put(`/api/organizations/${organizationId}`, data);
    },
    deleteOrganization(organizationId) {
      return instanceAxios.delete(`/api/organizations/${organizationId}`);
    },
    // Organization User
    getOrganizationUsers(organizationId, params) {
      return instanceAxios.get(`/api/organizations/${organizationId}/users`, { params });
    },
    attachOrganizationUsers(organizationId, data) {
      return instanceAxios.post(`/api/organizations/${organizationId}/users/attach`, data);
    },
    detachOrganizationUser(organizationId, userId) {
      return instanceAxios.delete(`/api/organizations/${organizationId}/users/${userId}`);
    },
    createOrganizationUser(organizationId, data) {
      return instanceAxios.post(`/api/organizations/${organizationId}/users`, data);
    },
    updateOrganizationUser(organizationId, userId, data) {
      return instanceAxios.put(`/api/organizations/${organizationId}/users/${userId}`, data);
    },
    // Organization Device 
    getOrganizationDevices(organizationId, params) {
      return instanceAxios.get(`/api/organizations/${organizationId}/devices`, { params });
    },
    getDeviceDetail(organizationId, deviceId) {
      return instanceAxios.get(`/api/organizations/${organizationId}/devices/${deviceId}`);
    },
    createOrganizationDevice(organizationId, data) {
      return instanceAxios.post(`/api/organizations/${organizationId}/devices`, data);
    },
    updateOrganizationDevice(organizationId, deviceId, data) {
      return instanceAxios.put(`/api/organizations/${organizationId}/devices/${deviceId}`, data);
    },
    deleteOrganizationDevice(organizationId, deviceId) {
      return instanceAxios.delete(`/api/organizations/${organizationId}/devices/${deviceId}`);
    },
    getDeviceLocation(deviceId, params) {
      return instanceAxios.get(`/api/devices/${deviceId}/locations`, { params });
    },
    getDevicePlaceLog(organizationId, deviceId, params) {
      return instanceAxios.get(`/api/organizations/${organizationId}/devices/${deviceId}/placeLogs`, { params });
    },
    getDevicePlaceLogTransform(organizationId, deviceId, params) {
      return instanceAxios.get(`/api/organizations/${organizationId}/devices/${deviceId}/placeLogs/transform`, { params });
    },
    getDeviceAggregate(deviceId, params) {
      return instanceAxios.get(`/api/devices/${deviceId}/locations/aggregate`, { params });
    },
    getDeviceDistance(deviceId, params) {
      return instanceAxios.get(`/api/devices/${deviceId}/locations/distance`, { params });
    },
    getDeviceAddress(organizationId, deviceId) {
      return instanceAxios.get(`/api/organizations/${organizationId}/devices/${deviceId}/lastaddress`);
    },
    // Organization Place 
    getOrganizationPlaces(organizationId, params) {
      return instanceAxios.get(`/api/organizations/${organizationId}/places`, { params });
    },
    getPlaceDetail(organizationId, placeId) {
      return instanceAxios.get(`/api/organizations/${organizationId}/places/${placeId}`);
    },
    createOrganizationPlace(organizationId, data) {
      return instanceAxios.post(`/api/organizations/${organizationId}/places`, data);
    },
    updateOrganizationPlace(organizationId, placeId, data) {
      return instanceAxios.put(`/api/organizations/${organizationId}/places/${placeId}`, data);
    },
    deleteOrganizationPlace(organizationId, placeId) {
      return instanceAxios.delete(`/api/organizations/${organizationId}/places/${placeId}`);
    },
    getPlaceLog(organizationId, placeId, params) {
      return instanceAxios.get(`/api/organizations/${organizationId}/places/${placeId}/placeLogs`, { params });
    },
    // Organization Collection
    getOrganizationCollection(organizationId, params) {
      return instanceAxios.get(`/api/organizations/${organizationId}/collections`, { params })
    },
    getOrganizationDeviceCollection(organizationId, params) {
      return instanceAxios.get(`/api/organizations/${organizationId}/device_collections`, { params })
    },
    // Organization Fleet Model 
    getOrganizationFleetModels(organizationId, params) {
      return instanceAxios.get(`/api/organizations/${organizationId}/fleet_models`, { params })
    },
    createOrganizationFleetModel(organizationId, data) {
      return instanceAxios.post(`/api/organizations/${organizationId}/fleet_models`, data)
    },
    updateOrganizationFleetModel(organizationId, fleetModelId, data) {
      return instanceAxios.put(`/api/organizations/${organizationId}/fleet_models/${fleetModelId}`, data)
    },
    deleteOrganizationFleetModel(organizationId, fleetModelId) {
      return instanceAxios.delete(`/api/organizations/${organizationId}/fleet_models/${fleetModelId}`)
    }
};

// also support for single import
export default endpoints;